import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Spinner from '../app/shared/Spinner';
import { isEmpty } from 'lodash';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const IgmDashboard = lazy(() => import('./IgmDashboard/IgmDashboard'));
const RspDashboard = lazy(() => import('./IgmDashboard/RspDashboard'));
const Login = lazy(() => import('./user-pages/Login'));
const VideoManager = lazy(() => import('./VideoManager/VideoManager'));
const FlagManager = lazy(() => import('./FlagManager/FlagManager'));
const SearchHistory = lazy(() => import('./SearchHistory/SearchHistory'));
const DeliveryManager = lazy(() => import('./DeliveryManager/DeliverManager'));
const DeliveryData = lazy(() => import('./DeliveryManager/DeliveryData'));
const OrderManager = lazy(() => import('./OrderManager/OrderManager'));
const InvoiceManager = lazy(() => import('./OrderManager/InvoiceManager'));
const OndcProducts = lazy(() => import('./Ondc/OndcProducts'));
const OndcOrderDetails = lazy(() => import('./Ondc/OndcOrderDetails'));
const LongDistance = lazy(() => import('./OrderManager/LongDistance'));
const CustomerOrderManager = lazy(() => import('./OrderManager/CustomerOrders'));
const OrderSearch = lazy(() => import('./OrderManager/OrderSearch'));
const InvoiceDetail = lazy(() => import('./OrderManager/InvoiceDetail'));
const BuddyInvoiceDetail = lazy(() => import('./BuddyReward/ViewBuddReward'));
const WithdrawalInvoiceDetail = lazy(() => import('./AccountManager/ViewWithdrawalManager'));
const InvoiceSellerDetail = lazy(() => import('./SellerReward/ViewSellerReward'));
const KikoPaymentDetail = lazy(() => import('./AccountManager/KikopaymentView'));
const FlagView = lazy(() => import('./FlagManager/FlagReview'));
const CoupanView = lazy(() => import('./OrderManager/ViewCoupan'));
const SellerManager = lazy(() => import('./UserManager/SellerManager'));
const VendorManager = lazy(() => import('./UserManager/VendorManager'));
const InfluencerManager = lazy(() => import('./UserManager/InfluencerManager'));
const ViewInfluencer = lazy(() => import('./UserManager/ViewInfluencer'));
const DeletedAccounts = lazy(() => import('./UserManager/DeletedAccounts'));
const SearchUsers = lazy(() => import("./UserManager/UserSearch"));
const ViewDelivery = lazy(() => import("./UserManager/ViewDelivery"));
const CustomerListedSeller = lazy(() => import("./UserManager/CustomerListedSeller"));
const TaskbucksBuyers = lazy(() => import("./MarketingLeads/TaskbucksBuyers"));
const PickMyWork = lazy(() => import("./MarketingLeads/PickMyWork"));
const VendorRequest = lazy(() => import('./RequestManager/VendorRequest'));
const EventManager = lazy(() => import('./EventManager/EventManager'));
const LiveUser = lazy(() => import('./EventManager/LiveUser'));
const AddEvent = lazy(() => import('./EventManager/AddEvent'));
const RewardCoupon = lazy(() => import('./OrderManager/RewardCoupon'));
const RewardProduct = lazy(() => import('./OrderManager/RewardProduct'));
const CategoryManager = lazy(() => import('./ProductManager/CategoryManager'));
const settingManager = lazy(() => import('./settingManager/index'));
const RewardManager = lazy(() => import('./ProductManager/RewardManager'));
const ReportsManager = lazy(() => import('./ReportsManager/ReportsManager'));
const CommunicationManager = lazy(() => import('./CommunicationManager/CommunicationManager'));
const AddReward = lazy(() => import('./ProductManager/AddReward'));
const ReviewRequestVendors = lazy(() => import("./RequestManager/ReviewVendorRequest"))
const ViewSeller = lazy(() => import("./UserManager/ViewSeller"))
const BuyersAddress = lazy(() => import("./UserManager/BuyersAddress"))
const AddEditAddress = lazy(() => import("./UserManager/AddEditAddress"))
const CustomerManager = lazy(() => import('./UserManager/CustomerManager'));
const EarnHistory = lazy(() => import('./SellerReward/EarnHistory'));
const EarnHistoryBuddy = lazy(() => import('./BuddyReward/EarnHistory'));
const RewardWithdrawal = lazy(() => import('./BuddyReward/RewardWithdrawal'));
const RewardSeller = lazy(() => import('./SellerReward/SellerRewardWithdrawal'));
const WithdrawalManager = lazy(() => import('./AccountManager/WithdrawalManager'));
const NACkikopayment = lazy(() => import('./AccountManager/NACkikopayment'));
const OrderDetails = lazy(() => import('./OrderManager/OrderDetails'));
const VideoOrderManager = lazy(() => import('./AccountManager/VideoCallOrders'))
const InfluencerRequest = lazy(() => import('./RequestManager/InfluencerRequest'));
const OndcOrderManager = lazy(() => import("./OndcOrderManager/OndcOrderManager"));
const OndcPendingOrder = lazy(() => import("./OndcOrderManager/OndcPendingOrder"));
const OndcReturn = lazy(() => import("./OndcOrderManager/OndcReturn"));
const MicroWebsiteOrderManager = lazy(() => import("./OndcOrderManager/MicroWebsiteOrderManager"));
const OndcSettlement = lazy(() => import("./OrderSettlement/OndcSettlement"));
const OndcSeller = lazy(() => import("./UserManager/OndcManager"));
const UrlGenerator = lazy(() => import("./UrlGenerator/UrlGenerator"));
const MicroWebsiteSettlement = lazy(() => import("./OrderSettlement/MicroWebsiteSettlement"));
const OndcOrderAdmin = lazy(() => import("./OndcOrderManager/OndcOrderAdmin"));
const LogisticOrderAdmin = lazy(() => import("./OndcOrderManager/LogisticOrderAdmin"));
const OndcOffersManager = lazy(() => import("./OndcOrderManager/OndcOffersManager"));
// const NotificationManager = lazy(() =>
//   import('./MarketingManager/NotificationManager')
// );
const CallEnquiry = lazy(() => import('./SupportManager/CallEnquiry'));
const OfflineOrderDetail = lazy(() => import('./OfflineOrders/OrderDetails'));
const OrderSuccess = lazy(() => import('./OfflineOrders/OrderSuccess'));
const Checkout = lazy(() => import('./OfflineOrders/Checkout'));
const AddressListing = lazy(() => import('./OfflineOrders/AddressListing'));
const ViewEvents = lazy(() => import('./EventManager/ViewEvents'));
const SelectAddress = lazy(() => import('./OfflineOrders/SelectAddress'));
const OrderDetail = lazy(() => import('./OfflineOrders/AddressListing'));
const ViewUsers = lazy(() => import('./EventManager/ViewUsers'));
const VendorWallet = lazy(() => import('./WalletManager/VendorWallet'));
const OfferWallet = lazy(() => import('./WalletManager/OfferWallet'));
const coinManager = lazy(() => import('./marketingManager/coinManager'));
const PreRazorpay = lazy(() => import('./OfflineOrders/PreRazorpay'));
const SellerOrders = lazy(() => import('./GstManager/SellerOrders'));
const SellerInvoice = lazy(() => import('./SellerInvoice/SellerInvoice'));
const BuyerOrders = lazy(() => import('./GstManager/BuyerOrders'));
const OndcGstManager = lazy(() => import('./GstManager/OndcGstManager'));
const CatalogueManager = lazy(() => import('./CatalogueManager/CatalogueManager'));
const CatalogueEan = lazy(() => import('./CatalogueManager/CatalogueEan'));
const MasterCatalogueManager = lazy(() => import('./CatalogueManager/MasterCatalogueManager'));
const LogsManager = lazy(() => import('./LogManager/LogsManager'));
const BuyerDashboard = lazy(() => import('./BuyerManager/BuyerDashboard'));
const BuyerOndcOrders = lazy(() => import('./BuyerManager/BuyerOndcOrders'));
const BuyerOndcOrderManager = lazy(() => import('./BuyerManager/BuyerOndcOrderManager'));
const BuyerPendingOrder = lazy(() => import('./BuyerManager/BuyerPendingOrder'));
const BuyerReportManager = lazy(() => import('./BuyerManager/BuyerReportManager'));
const BuyerUserManager = lazy(() => import('./BuyerManager/BuyerUserManager'));
const ViewBuyer = lazy(() => import('./BuyerManager/viewBuyer'));
const BuyerLogManager = lazy(() => import('./BuyerManager/LogManager'));
const StoreManager = lazy(() => import('./BuyerManager/StoreManager'));
const ViewBuyerSeller = lazy(() => import('./BuyerManager/viewSeller')); 
const BuyeCatalogue =  lazy(() => import('./BuyerManager/CatalogueManager'));
const RejectionCatalogue = lazy(() => import('./BuyerManager/rejectedCatalogues'))







class AppRoutes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: !isEmpty(JSON.parse(localStorage.getItem('auth'))),
    };
  }

  render() {
    const { isLoggedIn } = this.state;
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path='/dashboard' component={Dashboard}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/igm-dashboard' component={IgmDashboard}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/rsp-dashboard' component={RspDashboard}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/vendor-requests/review/:id' component={ReviewRequestVendors}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/seller/review/:id' component={ViewSeller}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/buyers-address' component={BuyersAddress}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/add-edit/address' component={AddEditAddress}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/delete-accounts' component={DeletedAccounts}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/video-call-orders' component={VideoOrderManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/events' component={EventManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/events/view/:id' component={ViewEvents}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/live-user' component={LiveUser}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/events/add' component={AddEvent}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          {<Route exact path='/events/users/:id' component={ViewUsers}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>}
          <Route exact path='/orders/view/:id' component={OrderDetails}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>

          <Route exact path='/videos' component={VideoManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/flag' component={FlagManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/search' component={SearchHistory}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/delivery-manager' component={DeliveryManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/delivery-data' component={DeliveryData}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/orders' component={OrderManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/reward-orders-coupon' component={RewardCoupon}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/reward-orders-product' component={RewardProduct}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/invoices' component={InvoiceManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/longdistance' component={LongDistance}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/customer-order-manager' component={CustomerOrderManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/OrderSearch' component={OrderSearch}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/invoices/view/:id' component={InvoiceDetail}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/buddy-Withdrawal/view/:id' component={BuddyInvoiceDetail}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/withdrawal-manager/view/:id' component={WithdrawalInvoiceDetail}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/Withdrawal-seller/view/:id' component={InvoiceSellerDetail}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/Kikopayment/view/:id' component={KikoPaymentDetail}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/flag/view/:id' component={FlagView}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/coupan/view/:id' component={CoupanView}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/seller' component={SellerManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/vendors' component={VendorManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/ondc-seller' component={OndcSeller}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/customer-manager' component={CustomerManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/influencers' component={InfluencerManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/influencers/view/:id' component={ViewInfluencer}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/search-users' component={SearchUsers}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/view-delivery' component={ViewDelivery}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/customer-list-seller' component={CustomerListedSeller}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/taskbucks-buyers' component={TaskbucksBuyers}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/ondc-order-manager' component={OndcOrderManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/ondc-pending-order' component={OndcPendingOrder}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/microwebsite-order-manager' component={MicroWebsiteOrderManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/ondc-settlement' component={OndcSettlement}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>

          <Route exact path='/ondc-return' component={OndcReturn}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/microwebsite-settlement' component={MicroWebsiteSettlement}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/pickMyWork-users' component={PickMyWork}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          {/* <Route exact path='/notifications' component={NotificationManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route> */}
          <Route exact path='/vendor-requests' component={VendorRequest}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/settings' component={settingManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/category-manager' component={CategoryManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/reward-manager' component={RewardManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/reward-manager/add/:id' component={AddReward}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/call-enquiry' component={CallEnquiry}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/coin-manager' component={coinManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/reward-seller' component={EarnHistory}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/report-manager' component={ReportsManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/communication-manager' component={CommunicationManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/buddy-Withdrawal' component={RewardWithdrawal}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/Withdrawal-seller' component={RewardSeller}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/withdrawal-manager' component={WithdrawalManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/NAC-kikopayment' component={NACkikopayment}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/buddy-reward' component={EarnHistoryBuddy}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/vendor-wallet' component={VendorWallet}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/selers-orders' component={SellerOrders}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/invoice-sellers' component={SellerInvoice}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/buyer-orders' component={BuyerOrders}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/ondc-gst-manager' component={OndcGstManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/offer-wallet' component={OfferWallet}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/catalogues' component={CatalogueManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/price-catalogues' component={CatalogueEan}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/ondc-orders-admin' component={OndcOrderAdmin}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/logistic-orders-admin' component={LogisticOrderAdmin}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/ondc-offers' component={OndcOffersManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/master-catalogues' component={MasterCatalogueManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/log-manager' component={LogsManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>

          <Route exact path='/buyer-dashboard' component={BuyerDashboard}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>

          <Route exact path='/buyer-ondc-orders' component={BuyerOndcOrders}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/buyer-ondc-order-manager' component={BuyerOndcOrderManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/buyer-ondc-pending-order' component={BuyerPendingOrder}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/buyer-log-manager' component={BuyerLogManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/buyer-user-manager' component={BuyerUserManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/buyer/review/:id' component={ViewBuyer}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/buyer/StoreManager' component={StoreManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/store/review/:id' component={ViewBuyerSeller}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/buyer/Catalogue' component={BuyeCatalogue}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/buyer/Rejection-Catalogue' component={RejectionCatalogue}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/order/:id' component={OfflineOrderDetail} />
          <Route exact path='/select-address' component={SelectAddress} />
          <Route exact path='/select-address/address-listing' component={OrderDetail} />
          <Route exact path='/checkout' component={Checkout} />
          <Route exact path='/pre-razorpay' component={PreRazorpay} />
          <Route exact path='/order-success' component={OrderSuccess} />
          <Route exact path='/address-listing' component={AddressListing} />
          <Route exact path='/ondc-products' component={OndcProducts} />
          <Route exact path='/ondc-order-details' component={OndcOrderDetails} />
          <Route exact path='/url-generator' component={UrlGenerator} />
          <Route
            exact
            path='/influencer-requests'
            component={InfluencerRequest}
          >
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/login' component={Login} />
          <Redirect to='/login' />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
